import {
  DefaultButton,
  FontWeights,
  IButtonStyles,
  IconButton,
  IIconProps,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import { trackPageView } from '../../../Services/AppInsights';
import { analyticsServiceContext } from '../../../Services/API/AnalyticsService';
import AnalyticsJobs from '../../../Components/AnalyticsJobs/AnalyticsJobs';
import { AppCatalogServiceContext } from '../../../Services/AppCatalogService';

enum Action {
  None,
  StartAnalytics,
  PurgeAll,
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const AnalyticsSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const analyticsService = useContext(analyticsServiceContext);
  const dialogService = useContext(DialogServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [action, setAction] = useState<Action>(Action.None);
  const [executingJob, setExecutingJob] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [viewJobs, setViewJobs] = useState<boolean>(false);
  const appCatalogService = useContext(AppCatalogServiceContext);
  const theme = appCatalogService!.GetCurrentTheme();

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: '60%',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.themePrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        boxSizing: 'border-box',
      },
    ],
    heading: {
      color: theme.palette.themePrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      maxHeight: '80vh',
      width: '60vw',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog('Settings saved', 'All settings have been saved.');
    setSaving(false);
  };

  const startAnalytics = async () => {
    setExecutingJob(true);
    await analyticsService?.StartAnalytics();
    setExecutingJob(false);
  };

  const purgeAll = async () => {
    setExecutingJob(true);
    await analyticsService?.PurgeAllConversations();
    setExecutingJob(false);
  };

  const executeAction = async (action: Action) => {
    switch (action) {
      case Action.StartAnalytics:
        await startAnalytics();
        break;
      case Action.PurgeAll:
        await purgeAll();
        break;
    }
    setAction(Action.None);
  };

  useEffect(() => {
    if (action !== Action.None) {
      switch (action) {
        case Action.StartAnalytics:
          dialogService?.showPromptDialog(
            'Start Analytics',
            'Are you sure you want to start analytics?',
            () => {
              executeAction(action);
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
        case Action.PurgeAll:
          dialogService?.showPromptDialog(
            'Purge All',
            'Are you sure you want to purge all data?',
            () => {
              dialogService?.showPromptDialog(
                'Purge All',
                'Are you really sure you want to purge all data? This action cannot be undone.',
                () => {
                  executeAction(action);
                },
                () => {
                  setAction(Action.None);
                }
              );
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 40 }}>
          <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
            <Label>Maximum conversation age (in days)</Label>
            <TextField
              styles={{ root: { width: 100 } }}
              value={settings.analytics_days_until_latest_run?.toString()}
              onChange={event =>
                setSettings({
                  ...settings,
                  analytics_days_until_latest_run: parseInt(event.currentTarget.value),
                })
              }
              type='number'
            />
            <Text variant={'xSmall'} block>
              This variable represents the number of days from today's date within which
              conversations can be considered for the latest analytics run. Conversations older than
              today's date minus the value of this variable will not be selected for analytics. This
              ensures that only the most recent conversations, based on the specified timeframe, are
              included.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
            <TextField
              value={settings.analytics_topics_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  analytics_topics_prompt: event.currentTarget.value,
                })
              }
              label='Analytics Prompt'
              multiline
              rows={20}
            />
            <Text variant={'xSmall'} block>
              The prompt used to associate predefined topics with the conversation.
              <br />
              <strong>
                {'{'}{'{'}conversation_history{'}'}{'}'}
              </strong>{' '}
              - The conversation history
              <br />
              <strong>
                {'{'}{'{'}topics{'}'}{'}'}
              </strong>{' '}
              - The topics of the conversation chat app 
            </Text>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              style={{ marginRight: 10 }}
              onClick={() => setAction(Action.StartAnalytics)}
              text={executingJob ? 'Executing...' : 'Start Analytics'}
              allowDisabledFocus
            />
            <Text variant={'xSmall'}>
              Starts the analytics timer job on demand for all Chat Apps.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              style={{ marginRight: 10 }}
              onClick={() => setAction(Action.PurgeAll)}
              text={executingJob ? 'Executing...' : 'Purge All'}
              allowDisabledFocus
            />
            <Text variant={'xSmall'}>Purges all conversations from analytics.</Text>
          </Stack.Item>
        </Stack>

        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? 'Saving...' : 'Save'}
                allowDisabledFocus
              />
              <DefaultButton
                onClick={() => setViewJobs(true)}
                text='View Jobs'
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>

        <Modal isOpen={viewJobs} onDismiss={() => setViewJobs(false)} isBlocking={false}>
          <div className={contentStyles.header}>
            <h2 className={contentStyles.heading}>Latest Analytics Jobs</h2>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel='Close popup modal'
              onClick={() => setViewJobs(false)}
            />
          </div>
          <div className={contentStyles.body}>
            <AnalyticsJobs />
          </div>
        </Modal>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default AnalyticsSettings;
